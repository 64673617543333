@import "mine.css/dist/mine.css";
@import "mine.css/dist/layout.css";

.gumcast-footer {
  text-align: center;
  margin: 3rem auto;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 10px;
}

.header-nav {
  display: grid;
  grid-template-columns: auto auto;
}

.breadcrumb {
  justify-self: start;
}

.auth-buttons {
  justify-self: end;
}

.auth-buttons > * {
  margin-left: 0.2em;
}

@supports(padding: max(0px)) {
  body {
      padding-top: env(safe-area-inset-top);
      padding-left: env(safe-area-inset-left);
      padding-right: env(safe-area-inset-right);
      padding-bottom: env(safe-area-inset-bottom);
  }
}

input {
  font-size: 20px;
  padding: 8px;
  margin-bottom: 5px;
}

input, label {
    display:block;
}
